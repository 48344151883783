import React from 'react';
import ProfileImage from './ProfileImage';
import SocialLinks from './SocialLinks';
import Section from './Section';

function Home() {
    return (
      <div className="profile-and-building">
        <div className="profile-section" style={{ float: 'left' }}>
          <ProfileImage />
          <SocialLinks />
        </div>
        <div className="profile-section" style={{ float: 'right' }}>
          <Section
            content={
              <React.Fragment>
                <p>
                  I am the CTO and co-founder of <a href="https://www.zeal.com" target="_blank" rel="noopener noreferrer">Zeal</a>.
                  We are a modern payroll platform built for large volume employers, such as staffing and gig economy companies.
                </p>
                <p>
                  Currently based in San Francisco, CA.
                </p>
              </React.Fragment>
            }
            id="profile"
            className="section profile"
          />
        </div>
      </div>
    );
  }

  export default Home;