import React from 'react';
import Section from './Section';
import { Link } from 'react-router-dom';

function Interests() {
  return (
    <div className="interests-container">
      <Section
        content={
          <React.Fragment>
            <nav className="interests-nav">
              <ul className="interests-list">
                <li><h3><Link to="/interests/story-of-sight">Story of Sight</Link></h3></li>
                <li><h3><Link to="/interests/sportsball">Sportsball!</Link></h3></li>
                <li><h3><Link to="/interests/tennis-story">The Tennis Story</Link></h3></li>
                <li><h3><Link to="/interests/dvaita-vedanta">Dvaita Vedanta</Link></h3></li>
              </ul>
            </nav>
          </React.Fragment>
        }
        id="interests"
        className="section interests"
      />
    </div>
  );
}

export default Interests;