export const blogContent = {
    'story-of-sight': {
      title: 'The Story of Sight',
      content: `
        <p>Vision is one of our most crucial senses, allowing us to perceive the world around us in vivid detail. But have you ever wondered how our eyes actually work?</p>
  
        <p>The journey of sight begins when light enters our eyes through the cornea. This transparent outer layer acts as a protective shield and helps to focus the incoming light. The light then passes through the pupil, which can dilate or constrict to control the amount of light entering the eye.</p>
  
        <p>Next, the light reaches the lens, which further focuses it onto the retina at the back of the eye. The retina is covered in millions of light-sensitive cells called rods and cones. Rods are responsible for vision in low light conditions, while cones allow us to see color and fine detail.</p>
  
        <p>These cells convert the light into electrical signals, which are then sent to the brain via the optic nerve. Our brain processes these signals, interpreting them as the images we perceive.</p>
  
        <p>The complexity and precision of this process are truly remarkable, allowing us to experience the world in all its visual splendor.</p>
      `
    },
    // Add more blog articles here
  };