import React from 'react';
import { FaLinkedin, FaTwitter } from 'react-icons/fa';

const SocialLinks = () => {
  return (
    <div className="social-links">
      <a href="https://www.linkedin.com/in/pranab-krishnan/" target="_blank" rel="noopener noreferrer">
        <FaLinkedin />
      </a>
      <a href="https://x.com/PranabKrishnan" target="_blank" rel="noopener noreferrer">
        <FaTwitter />
      </a>
    </div>
  );
};

export default SocialLinks;