import React from 'react';

const Section = ({ title, content, id, className }) => {
  return (
    <section id={id} className={className}>
      {title && <h2>{title}</h2>}
      {content && <p>{content}</p>}
    </section>
  );
};

export default Section;