import { useParams } from 'react-router-dom';
import Section from './Section';
import { blogContent } from './content/blogContent';


function Article() {
    const { articleId } = useParams();
    const article = blogContent[articleId];
  
    if (!article) {
      return <div>Article not found</div>;
    }
  
    return (
      <Section
        content={
          <div className="article-container">
            <div className="article">
              <h1>{article.title}</h1>
              <div dangerouslySetInnerHTML={{ __html: article.content }} />
            </div>
          </div>
        }
        id={`blog-${articleId}`}
        className="section article"
      />
    );
  }
  
  export default Article;