import React from 'react';
import profilePic from './img/profile-pic.jpg'; // Adjust the path as needed

function ProfileImage() {
  return (
    <div className="profile-image">
      <img src={profilePic} alt="PK" />
    </div>
  );
}

export default ProfileImage;