import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import './App.css';
import Header from './components/Header';
import Home from './components/Home';
import Interests from './components/Interests';
import Article from './components/Article';

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <main className="main-content">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/interests" element={<Interests />} />
            <Route path="/interests/:articleId" element={<Article />} />
          </Routes>
        </main>
      </div>
    </Router>
  );
}

export default App;